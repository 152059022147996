<template>
  <DocumentRoot></DocumentRoot>
</template>

<script>
import DocumentRoot from "@/components/document/DocumentRoot";

export default {
  name: "DocumentPage",
  data() {
    return {
      dividerPosition: 20
    }
  },
  components: {
    DocumentRoot
  },
  methods: {
    handleDragging(e) {
      const percentage = (e.pageX / window.innerWidth) * 100;

      if (percentage >= 10 && percentage <=90) {
        this.dividerPosition = percentage.toFixed(2);
      }
    },
    startDragging() {
      document.addEventListener('mousemove', this.handleDragging)
    },
    endDragging() {
      document.removeEventListener('mousemove', this.handleDragging)
    }
  }
}
</script>

<style scoped>
.divider {
  height: 100vh;
  width: 4px;
  background: black;
  transform: translateX(-3px);
  position: fixed;
  top: 56px;
  z-index: 1;
  cursor: ew-resize;
}

.editor {
  top: 56px;
}
</style>
