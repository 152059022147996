

export class Price {
    _dateTime;

    _open;

    _close;

    _high;

    _low;

    constructor(dateTime, open, close, high, low) {
        this._dateTime = dateTime;
        this._open = open;
        this._close = close;
        this._high = high;
        this._low = low;
    }

    get dateTime() {
        return this._dateTime;
    }

    set dateTime(dateTime) {
        this._dateTime = dateTime
    }

    get open() {
        return this._open;
    }

    get close() {
        return this._close;
    }

    get high() {
        return this._high;
    }

    get low() {
        return this._low;
    }

    static fromJson(j) {
        if (j) {
            const dateTime = String(j['date_time'].split("T")[0]);
            const open = j['open'];
            const close = j['close'];
            const low = j['low'];
            const high = j['high'];
            return new Price(dateTime, open, close, low, high);
        }

        return null;
    }

    toRecord() {
        return { time: this.dateTime, open: this.open, high: this.high, low: this.low, close: this.close };
    }

}