import { defineStore } from 'pinia'
import Crypto from 'crypto-js';
import Cookie from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';

const cookieName = 'cookieName';
const tokenStorageKey = 'token';
const profileStorageKey = 'profile';

const encryptionToken = Cookie.get(cookieName) || uuidv4();
Cookie.set(cookieName, encryptionToken, {secure: true, expires: 180});

export const userProfile = defineStore('userProfile', {
    state: () => ({
        userToken: null,
        profile: {
            token: null,
            name: null
        },
    }),
    getters: {
        isLogin(state) {
            if (state.userToken) {
                return true;
            }

            const store = window.localStorage.getItem(tokenStorageKey);
            if (store) {
                try {
                    const bytes = Crypto.AES.decrypt(store, encryptionToken);
                    state.userToken = bytes.toString(Crypto.enc.Utf8);

                    // Use this object in the future
                    const profileStore = window.localStorage.getItem(profileStorageKey);
                    if (profileStore) {
                        const profileInStr = Crypto.AES.decrypt(profileStore, encryptionToken).toString(Crypto.enc.Utf8);
                        state.profile = JSON.parse(profileInStr);
                    }

                    return state.userToken != null;
                } catch (e) {
                    window.localStorage.removeItem(tokenStorageKey);
                }
            }
            return false;
        },
        token(state) {
            return state.userToken;
        },
        name(state) {
            if (state.profile) {
                return state.profile.name;
            }

            return null;
        }
    },
    actions: {
        setToken(token) {
            this.userToken = token;
            // Encrypt
            const store = Crypto.AES.encrypt(token, encryptionToken).toString();
            window.localStorage.setItem(tokenStorageKey, store);
        },
        setProfile(profile) {
          this.profile = profile;
          const store = Crypto.AES.encrypt(JSON.stringify(profile), encryptionToken).toString();
          window.localStorage.setItem(profileStorageKey, store);
        },
        removeUserInfo() {
            this.userToken = null;
            this.profile = null;
            window.localStorage.removeItem(tokenStorageKey);
            window.localStorage.removeItem(profileStorageKey);
        }
    }
})
