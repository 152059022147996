<template>
  <div id="sidebar" class="d-flex flex-column flex-shrink-0 p-0 text-white sticky-top">
<!--    <a href="/" class="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none">-->
<!--      <svg class="bi me-2" width="40" height="32"><use xlink:href="#bootstrap"/></svg>-->
<!--      <span class="fs-4">Workspace</span>-->
<!--    </a>-->
<!--    <hr>-->
    <ul class="nav nav-pills flex-column align-items-start" v-for="(section, index) in functionNames" :key="index">
      <li class="nav-item">
        <router-link :to="section.ref" class="nav-link text-white">
          <i :class="['bi', section.icon]"
             :style="{fontSize: `${section.iconHoverSize}rem`, color: `${section.isActive ? 'cornflowerblue' : 'white'}`}"
             v-on:mouseover="enlarge(index)" v-on:mouseleave="restoreSize(index)"
             @click="onClick(index)"
          ></i>
        </router-link>
      </li>
<!--      <li>-->
<!--        <router-link to="/alpha" class="nav-link text-white">-->
<!--          <i class="bi bi-card-checklist" style="font-size: 1.5rem"></i>-->
<!--        </router-link>-->
<!--      </li>-->
<!--      <li>-->
<!--        <a href="#" class="nav-link text-white">-->
<!--          <i class="bi bi-collection" style="font-size: 1.5rem"></i>-->
<!--        </a>-->
<!--      </li>-->
    </ul>
  </div>
</template>

<script>
export default {
  name: "SideBar",
  data () {
    return {
      iconHoverSize: 1.5,
      functionNames: [
        {
          name: 'Studio',
          ref: '/studio',
          icon: 'bi-boxes',
          iconHoverSize: 1.5,
          isActive: false
        },
        {
          name: 'Alpha',
          ref: '/alpha',
          icon: 'bi-card-checklist',
          iconHoverSize: 1.5,
          isActive: false
        },
        {
          name: 'NoteBook',
          ref: '/notebook',
          icon: 'bi-journals',
          iconHoverSize: 1.5,
          isActive: false
        },
        {
          name: 'REPL',
          ref: '/repl',
          icon: 'bi-display',
          iconHoverSize: 1.5,
          isActive: false
        },
        {
          name: 'Chart',
          ref: '/chart',
          icon: 'bi-bar-chart-line',
          iconHoverSize: 1.5,
          isActive: false
        },
        // {
        //   name: 'Strategies',
        //   ref: '/strategies',
        //   icon: 'bi-collection',
        //   iconHoverSize: 1.5,
        //   isActive: false
        // }
      ]
    }
  },
  methods: {
    enlarge(index) {
      this.functionNames[index].iconHoverSize = this.functionNames[index].iconHoverSize * 1.5;
    },
    restoreSize(index) {
      this.functionNames[index].iconHoverSize = 1.5;
    },
    onClick(index) {
      for (let i = 0; i < this.functionNames.length; i++) {
        this.functionNames[i].isActive = i === index;
      }
    }
  }
}
</script>

<style scoped>

#sidebar {
  position: fixed;
  width: inherit;
  height: calc(100vh - 56px);
  left: 0;
  top: 56px;
  background: #222222;
}
</style>
