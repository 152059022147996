

export function calculateSMA(data, config) {
    const count = config.window;
    let avg = function(data) {
        let sum = 0;
        for (let i=0; i < data.length; i++) {
            sum += data[i].close;
        }

        return sum /data.length;
    }
    let result = []
    for (let i= count - 1, len=data.length; i < len; i++) {
        let val = avg(data.slice(i - count + 1, i));
        result.push({time: data[i].time, value: val});
    }
    return result;
}