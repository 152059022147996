<template>
  <!-- Masthead-->
  <header class="masthead">
    <div class="container position-relative">
      <div class="row justify-content-center">
        <div class="col-xl-6">
          <div class="text-center text-white">
            <!-- Page heading-->
            <h1 class="mb-5">Empower your investment decisions with advanced financial technologies</h1>
            <router-link to="/login">
              <button type="button" class="btn btn-success btn-lg">Get Started</button>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </header>

  <!-- Icons Grid-->
  <section class="features-icons bg-light text-center">
    <div class="container">
      <div class="row">
        <div class="col-lg-4">
          <div class="features-icons-item mx-auto mb-5 mb-lg-0 mb-lg-3">
            <div class="features-icons-icon d-flex"><i class="bi-graph-up m-auto text-primary"></i></div>
            <h3>Smart indicators</h3>
            <p class="lead mb-0">Incorporate advanced indicators into your decision making</p>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="features-icons-item mx-auto mb-5 mb-lg-0 mb-lg-3">
            <div class="features-icons-icon d-flex"><i class="bi-window m-auto text-primary"></i></div>
            <h3>Backtesting</h3>
            <p class="lead mb-0">Backtest your investment strategies for free with our quant engine and web IDE</p>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="features-icons-item mx-auto mb-5 mb-lg-0 mb-lg-3">
            <div class="features-icons-icon d-flex"><i class="bi-layers m-auto text-primary"></i></div>
            <h3>Learning</h3>
            <p class="lead mb-0">Well-known algorithmic trading strategies shared by experts and members</p>
          </div>
        </div>
<!--        <div class="col-lg-4">-->
<!--          <div class="features-icons-item mx-auto mb-0 mb-lg-3">-->
<!--            <div class="features-icons-icon d-flex"><i class="bi-terminal m-auto text-primary"></i></div>-->
<!--            <h3>Live trading</h3>-->
<!--            <p class="lead mb-0">Make your algorithm online</p>-->
<!--          </div>-->
<!--        </div>-->
      </div>
    </div>
  </section>

  <!-- Image Showcases-->
  <section class="showcase">
    <div class="container-fluid p-0">
      <div class="row g-0">
        <div class="col-lg-6 order-lg-2 text-white showcase-img">
          <img src="../../assets/alpha-factor-gif.gif" alt="Alpha factor workspace">
        </div>
        <div class="col-lg-6 order-lg-1 my-auto showcase-text">
          <h2>Backtesting</h2>
          <p class="lead mb-0">
            Verify your strategy with a rigorous backtesting will not only help you avoid loss but also discover new strategies.
          </p>
        </div>
      </div>
      <div class="row g-0">
        <div class="col-lg-6 text-white showcase-img"></div>
        <div class="col-lg-6 my-auto showcase-text">
          <h2>Learning</h2>
          <p class="lead mb-0">
            The best way to create a new investment strategy is to learn from successful ideas.
          </p>
        </div>
      </div>
<!--      <div class="row g-0">-->
<!--        <div class="col-lg-6 order-lg-2 text-white showcase-img"></div>-->
<!--        <div class="col-lg-6 order-lg-1 my-auto showcase-text">-->
<!--          <h2>Live trading</h2>-->
<!--          <p class="lead mb-0">-->
<!--            Ready to make your algorithm on live and invest for you automatically.-->
<!--          </p>-->
<!--        </div>-->
<!--      </div>-->
    </div>
  </section>

  <!-- Footer-->
  <footer class="footer bg-light">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 h-100 text-center text-lg-start my-auto">
          <ul class="list-inline mb-2">
            <li class="list-inline-item"><a href="/about">About</a></li>
            <li class="list-inline-item">⋅</li>
            <li class="list-inline-item"><a href="#!">Contact</a></li>
            <li class="list-inline-item">⋅</li>
            <li class="list-inline-item"><a href="#!">Terms of Use</a></li>
            <li class="list-inline-item">⋅</li>
            <li class="list-inline-item"><a href="#!">Privacy Policy</a></li>
          </ul>
          <p class="text-muted small mb-4 mb-lg-0">&copy; Lemanee Software 2022. All Rights Reserved.</p>
        </div>
        <div class="col-lg-6 h-100 text-center text-lg-end my-auto">
          <ul class="list-inline mb-0">
            <li class="list-inline-item me-4">
              <a href="#!"><i class="bi-discord fs-3"></i></a>
            </li>
            <!-- <li class="list-inline-item me-4">
              <a href="#!"><i class="bi-twitter fs-3"></i></a>
            </li> -->
<!--            <li class="list-inline-item">-->
<!--              <a href="#!"><i class="bi-instagram fs-3"></i></a>-->
<!--            </li>-->
          </ul>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
name: "HomeRoot"
}
</script>

<style scoped>
.features-icons {
  padding-top: 7rem;
  padding-bottom: 7rem;
}
.features-icons .features-icons-item {
  max-width: 20rem;
}
.features-icons .features-icons-item .features-icons-icon {
  height: 7rem;
}
.features-icons .features-icons-item .features-icons-icon i {
  font-size: 4.5rem;
}

header.masthead {
  position: relative;
  background-color: #343a40;
  /*background: url("../assets/img/bg-masthead.jpg") no-repeat center center;*/
  background-size: cover;
  padding-top: 8rem;
  padding-bottom: 8rem;
}
header.masthead:before {
  content: "";
  position: absolute;
  background-color: #1c375e;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0.5;
}
header.masthead h1, header.masthead .h1 {
  font-size: 2rem;
}
@media (min-width: 768px) {
  header.masthead {
    padding-top: 12rem;
    padding-bottom: 12rem;
  }
  header.masthead h1, header.masthead .h1 {
    font-size: 3rem;
  }
}

.showcase .showcase-text {
  padding: 3rem;
}
.showcase .showcase-img {
  /*min-height: 10rem;*/
  /*background-size: cover;*/
  padding-top: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
  align-items: center;
}
@media (min-width: 768px) {
  .showcase .showcase-text {
    padding: 7rem;
  }
}

footer.footer {
  padding-top: 4rem;
  padding-bottom: 4rem;
}
</style>
