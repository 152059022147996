<template>
  <div class="code d-flex flex-column justify-content-center" @mouseup="endDragging()">
<!--    <h1>{{title}}</h1>-->
    <div class="container-fluid d-flex flex-column justify-content-end" :style="{height: `${100 - dividerPosition}%`}">
      <div class="d-flex flex-row justify-content-end mt-1 button-set">
        <button type="button" class="btn btn-primary" @click="triggerBacktest">
          <div class="d-flex flex-row">
            <i class="bi bi-play" style="font-size: 1.25rem"></i>
            <p class="vertical-center">Backtest</p>
          </div>
        </button>
        <button v-if="!this.codeBlock.isUpdated" type="button" class="btn btn-secondary" disabled>
          <div class="d-flex flex-row">
            <i class="bi bi-save" style="font-size: 1.25rem"></i>
            <p class="vertical-center">Save</p>
          </div>
        </button>
        <button v-else-if="this.codeBlock.isUpdated" type="button" class="btn btn-success" @click="saveCode">
          <div class="d-flex flex-row">
            <i class="bi bi-save" style="font-size: 1.25rem"></i>
            <p class="vertical-center">Save</p>
          </div>
        </button>
<!--        <button type="button" class="btn btn-success" disabled>-->
<!--          <div class="d-flex flex-row">-->
<!--            <i class="bi bi-cloud-arrow-up" style="font-size: 1.25rem"></i>-->
<!--            <p class="vertical-center">Live</p>-->
<!--          </div>-->
<!--        </button>-->
      </div>
      <div id="root" :style="{height: `${100 - 4}%`}"></div>
    </div>
<!--    Expand result button-->
    <i v-if="isResultCollapse" class="bi bi-arrow-up-square float"
       style="font-size: 1.5rem; z-index: 9999" @click="dividerPosition=40"></i>

    <div class="d-flex flex-column" :style="{height: `${dividerPosition}%`}">
      <div class="horizontal-divider" :style="{bottom: `${dividerPosition}%`}"
           @mousedown="startDragging()"
      ></div>
      <ResultCom :backtest-result="backtestResult"
                 :loading="isLoading"
                 @collapse="collapseResult"
      ></ResultCom>
    </div>
  </div>
</template>

<script>
import * as monaco from 'monaco-editor';
import ResultCom from "@/components/studio/ResultCom";
import restApi from "@/api/rest";
import {userProfile} from '@/store/store';
import {CodeBlock} from "@/models/codeBlockModel";
// import {ShowAutoCompletion} from "@/components/studio/autoCompletion";

import { MonacoLanguageClient, CloseAction, ErrorAction, MonacoServices, createConnection } from 'monaco-languageclient';
import { listen } from '@codingame/monaco-jsonrpc';
import normalizeUrl from 'normalize-url';

let editor = null;

const sampleCode = `
from custom_data import *

class BasicTemplateAlgorithm(BaseAlgorithm):
    def Initialize(self):
        self.SetStartDate(2021, 1, 1)
        self.SetEndDate(2021, 12, 1)
        self.SetCash(100000000)
        # Add stocks
        self.AddData(VnStock, "ACB", Resolution.Daily)
        self.AddData(VnStock, "FUEVFVND", Resolution.Daily)
        self.SetBenchmark("FUEVFVND")
        self.SetWarmUp(5, Resolution.Daily)

    def OnData(self, data):
        if not self.Portfolio.Invested:
            self.SetHoldings("ACB", 1)`;

export default {
  name: "CodeEditor",
  props: {
    currentCodeBlock: null
  },
  setup() {
    const profile = userProfile();
    return {profile};
  },
  data() {
    return {
      title: 'Code Editor',
      dividerPosition: 40,
      codeName: null,
      codeBlock: this.currentCodeBlock == null ? new CodeBlock(null, null, '', null): this.currentCodeBlock,
      backtestResult: this.currentCodeBlock == null ? null: JSON.parse(this.currentCodeBlock.result),
      isCodeUpdated: false,
      isLoading: null,
      isResultCollapse: false
    }
  },
  components: {
    ResultCom
  },
  computed: {
    prettyResult() {
      // console.log(this.backtestResult);
      return JSON.stringify(this.backtestResult, null, 2);
    }
  },
  methods: {
    async triggerBacktest() {
      // console.log(JSON.stringify({name: 'Test', content: this.editor.getValue()}));

      const url = `${process.env.VUE_APP_API_URL}leanbacktest`;
      // console.log(this.codeBlock);
      this.isLoading = true;
      this.codeBlock.code = editor.getValue();
      this.backtestResult = await (await restApi.post(url, {name: this.codeBlock.name, content: this.codeBlock.code})).json();
      this.isLoading = false;
      this.codeBlock.isUpdated = true;
    },
    async saveCode() {
      // Save backtest results
      let url = `${process.env.VUE_APP_BACKEND_URL}backtest/`;
      this.codeBlock.code = editor.getValue();
      const data = {name: this.codeBlock.name, code: this.codeBlock.code, result: JSON.stringify(this.backtestResult, null, 2)};
      // console.log(data);

      if (this.codeBlock.id != null) {
        // If id exists, update code instead
        url = `${process.env.VUE_APP_BACKEND_URL}backtest/${this.codeBlock.id}/`;
        await (await restApi.update(url,
            data,
            this.profile.token)).json();
        // console.log(response);
      } else {
        await (await restApi.post(url,
            data,
            this.profile.token)).json();
        // console.log(response);

        // todo: Error handling
      }

      // todo: Update the Sidebar list after saving

      // Change status to not update
      this.codeBlock.isUpdated = false;
    },
    handleDragging(e) {
      const percentage = (1 - e.pageY / window.innerHeight) * 100;

      if (percentage >= 30 && percentage <=90) {
        this.dividerPosition = percentage.toFixed(2);
      }
    },
    startDragging() {
      document.addEventListener('mousemove', this.handleDragging)
    },
    endDragging() {
      document.removeEventListener('mousemove', this.handleDragging)
    },
    collapseResult() {
      this.dividerPosition = 0;
      this.isResultCollapse = true;
    }
  },
  mounted() {
    // register Monaco languages
    monaco.languages.register({
      id: 'python',
      extensions: ['.py'],
      aliases: ['Python', 'python']
    });

    // create Monaco editor
    const value = this.codeBlock !== null ? this.codeBlock.code : '';
    const monacoEditor = monaco.editor.create(document.getElementById("root"), {
      // model: monaco.editor.createModel(value, 'python', monaco.Uri.parse('inmemory://model.json')),
      value: value,
      language: 'python',
      theme: 'vs-dark',
      automaticLayout: true,
      fontSize: 14
    });

    // install Monaco language client services
    MonacoServices.install(monaco);

    // create the web socket
    // const url = createUrl('0.0.0.0', 3000, '/python')
    // const url = 'wss://lemanee-language-server-njmwjci7sa-df.a.run.app/python'
    const url = createUrl('wss','lemanee-language-server-njmwjci7sa-df.a.run.app', null, '/python')
    const webSocket = new WebSocket(url);

    // console.log(url);
    const data = JSON.stringify({
      token: 'e1612e9bbab8945371a0e6b33903b404b6ef464e'
    })

    webSocket.addEventListener('open', function () {
      // console.log(`send token: ${data}`);
      webSocket.send(data);
    })

    // listen when the web socket is opened
    listen({
      webSocket,
      onConnection: connection => {
        // create and start the language client
        const languageClient = createLanguageClient(connection);
        const disposable = languageClient.start();
        // console.log(`Connected to "${url}" and started the language client.`);

        connection.onClose(() => disposable.dispose());
      }
    });

    function createLanguageClient(connection) {
      return new MonacoLanguageClient({
        name: "Python Language Client",
        clientOptions: {
          // use a language id as a document selector
          documentSelector: ['python'],
          // disable the default error handler
          errorHandler: {
            error: () => ErrorAction.Continue,
            closed: () => CloseAction.DoNotRestart
          }
        },
        // create a language client connection from the JSON RPC connection on demand
        connectionProvider: {
          get: (errorHandler, closeHandler) => {
            return Promise.resolve(createConnection(connection, errorHandler, closeHandler))
          }
        }
      });
    }

    function createUrl(protocol, hostname, port, path) {
      // const protocol = location.protocol === 'https:' ? 'wss' : 'ws';
      if (port) {
        return normalizeUrl(`${protocol}://${hostname}:${port}${path}`);
      }
      return normalizeUrl(`${protocol}://${hostname}${path}`);
    }


    // const monacoEditor = monaco.editor.create(document.getElementById("root"), {
    //   value: this.codeBlock !== null ? this.codeBlock.code : '',
    //   language: 'python',
    //   theme: 'vs-dark',
    //   automaticLayout: true
    // });

    // let i = 0;
    monacoEditor.onDidChangeModelContent(e => {
      if (e.changes[0].rangeOffset > 0) {
        this.codeBlock.isUpdated = true;
      }

      // if (i === 0) {
      //   ShowAutoCompletion({
      //     Universe: {
      //       ACB: "ACB",
      //       FUEVFVND: "FUEVFVND"
      //     }
      //   });
      // }
      // i = 1;
    })

    editor = monacoEditor;
  },
  created() {
    // this.$watch(
    //     () => this.$route.params,
    //     (toParams) => {
    //       this.fetchData(toParams.id);
    //     }
    // )

    this.$watch(() => this.currentCodeBlock, (codeBlock) => {
      if (codeBlock === null) {
        editor.setValue(sampleCode);
      } else {
        this.codeBlock = codeBlock;

        if (codeBlock.result) {
          this.backtestResult = JSON.parse(codeBlock.result);
        } else {
          this.backtestResult = null;
        }
        editor.setValue(codeBlock.code);
        this.isLoading = false;
      }
    })
  },
}
</script>

<style scoped>
.code {
  height: calc(100vh - 56px);
}

.button-set {
  height: 38px;
}

.btn {
  width: 120px;
  justify-content: center;
}

.code-canvas {
  height: calc(100vh - 36px);
}

#root {
  /*height:600px;*/
  border:1px solid #ccc;
  text-align: start;
  width: 100%;
  height: inherit;
}

.btn {
  margin: 0 10px;
}

.horizontal-divider {
  width: inherit;
  height: 6px;
  background: black;
  transform: translateY(-3px);
  z-index: 1;
  cursor: ns-resize;
}

.vertical-center {
  margin-top: 0;
  margin-left: 10px;
}

.float{
  position:fixed;
  width:60px;
  height:60px;
  bottom:40px;
  right:40px;
  color:#FFF;
  text-align:center;
}
</style>
