<template>
  <div id="file-sidebar" class="d-flex flex-column flex-shrink-0 p-0 bg-dark text-white sticky-top align-items-start">
    <div class="container-fluid d-flex flex-column align-items-end">
      <i class="bi bi-file-earmark-plus icon-btn" style="font-size: 1.5rem" @click="addNewFile"></i>
    </div>
    <div class="input-group mb-3">
      <input class="form-control" type="text" v-model="search" placeholder="Search" />
    </div>
    <ul class="container-fluid nav nav-pills flex-column align-items-start"
        v-for="(aFile, index) in filteredFileList" :key="index">
      <li v-on:mouseover="aFile.hover = true"
          v-on:mouseleave="aFile.hover = false"
          class="align-items-center"
          :class="{'d-flex':true, 'justify-content-between': true, 'nav-item': true, 'selected': aFile.isClicked}">
        <button :class="{'nav-link': true, 'text-white': !aFile.isNew, 'text-danger': aFile.isNew}"
                  @click="fetchCode(index)">
            {{ aFile.name }}
        </button>
        <a v-if="!aFile.isNew && (aFile.hover || aFile.isClicked)">
          <i class="bi bi-pencil-square icon-btn" style="font-size: 1.25rem" @click="editFileName(aFile)"></i>
          <i class="bi bi-trash icon-btn" style="font-size: 1.25rem" @click="onDelete(index)"></i>
        </a>

<!--        <input type="text" placeholder="Untitled"-->
<!--            class="input-name"-->
<!--               v-else-if="fileName.isNew"-->
<!--               v-on:keyup.enter = "onEnter(index, $event.target.value)">-->
<!--        <router-link :to="`/exp/${fileName.id}`" class="nav-link text-white">-->
<!--          {{ fileName.name }}-->
<!--        </router-link>-->
      </li>
    </ul>
  </div>
</template>

<script>
import restApi from "@/api/rest";
import {userProfile} from "@/store/store";
import {CodeBlock} from "@/models/codeBlockModel";

// const sampleCode = `
// import numpy as np
// from QuantConnect import *
// from QuantConnect.Algorithm import *
// QCAlgorithmFramework = QCAlgorithm
// QCAlgorithmFrameworkBridge = QCAlgorithm
// from custom_data import VnStock
//
// class BasicTemplateAlgorithm(QCAlgorithm):
//     def Initialize(self):
//         self.SetStartDate(2021, 1, 1)
//         self.SetEndDate(2021, 12, 1)
//         self.SetCash(100000000)
//         self.AddData(VnStock, "ACB", Resolution.Daily)
//         self.AddData(VnStock, "FUEVFVND", Resolution.Daily)
//         self.SetBenchmark("FUEVFVND")
//         self.SetWarmUp(5, Resolution.Daily)
//         self.Debug("===========>Backtest stock {}".format("ACB"))
//
//     def OnData(self, data):
//         if not self.Portfolio.Invested:
//             self.SetHoldings("ACB", 1)`;

export default {
  name: "DirectorySideBar",
  props: {
    fileNames: null
  },
  setup() {
    const profile = userProfile();
    return {profile};
  },
  data () {
    return {
      // Local list
      fileList: this.fileNames,
      search: ""
    }
  },
  methods: {
    addNewFile() {
      // const newFile = new CodeBlock(null, 'untitled', sampleCode, null);
      // newFile.isNew = true;
      // this.fileList.push(newFile);
      console.log('new file');
      this.$emit('newFile', 'untitled');
    },
    editFileName(aFile) {
      console.log('Edit file name');
      this.$emit('editFileName', aFile);
    },
    onEnter(index, val) {
      this.fileList[index].name = val;
      this.fileList[index].isNew = false;
      // console.log(this.fileList[index]);
    },
    async onDelete(index) {
      /**
       * Delete code block
       */
      const id = this.fileList[index].id;
      const url = `${process.env.VUE_APP_BACKEND_URL}backtest/${id}/`;
      const response = await restApi.delete(url, this.profile.token);

      console.log(response);
    },
    async fetchCode(index) {
      const id = this.fileList[index].id;
      this.fileList[index].isClicked = true;

      for (let i=0; i < this.fileList.length; i++) {
        if (i !== index) {
          this.fileList[i].isClicked = false;
        }
      }

      if (id) {
        const url = `${process.env.VUE_APP_BACKEND_URL}backtest/${id}/`;
        const result = await (await restApi.get(url, this.profile.token)).json();

        const codeBlock = new CodeBlock(result.id, result.name, result.code, result.result);
        // console.log(codeBlock);

        this.$emit('codeBlock', codeBlock);
      } else {
        this.$emit('codeBlock', this.fileList[index]);
      }
    }
  },
  computed: {
    filteredFileList() {
      return this.fileList.filter(f => {
        return f.name.toLowerCase().indexOf(this.search.toLowerCase()) !== -1;
      });
    }
  },
  created() {
    this.$watch(() => this.fileNames, (filenames) => {
      this.fileList = filenames;
    })
  },
}
</script>

<style scoped>
#file-sidebar {
  /*position: fixed;*/
  width: 100%;
  height: calc(100vh - 56px);
  left: 0;
  top: 56px;
  /*z-index: -1;*/
}

.selected {
  background: #b8b8b8;
  width: 100%;
  text-align: start;
  color: blue;
}

.nav-item:hover {
  background: #b8b8b8;
  width: 100%;
  text-align: start;
}

/*.hovered {*/
/*  background: #b8b8b8;*/
/*  width: 100%;*/
/*  text-align: start;*/
/*}*/

.btn-ext {
  background-color: transparent;
}

.input-name {
  width: 100%;
}

.bi {
  cursor: pointer;
}

.icon-btn {
  padding-right: 10px;
}

.icon-btn:hover {
  color: orange;
}

</style>
