<template>
  <section class="script">
    <iframe width="100%" height="100%" src="https://jupyterlite.github.io/demo/lab/index.html"></iframe>
  </section>
</template>

<script>
export default {
  name: "REPLCom",
  mounted() {
    // let newScript = document.createElement('script');
    // newScript.defer = true;
    // newScript.src = 'https://pyscript.net/latest/pyscript.js';
    // document.head.appendChild(newScript)
  }
}
</script>

<style scoped>

.script {
  padding-left: 0;
  text-align: start !important;
  height: 100vh;
}
</style>