<template>
  <div class="container-fluid g-0 editor">
<!--    <div v-if="intro" class="row g-0">-->
<!--      <h4 class="display-6">Let build your trading algorithms</h4>-->
<!--      <p class="lead">-->
<!--        <a class="btn btn-primary btn-lg" role="button" @click="startNow">Start now</a>-->
<!--      </p>-->
<!--    </div>-->

    <div v-if="!intro" class="row g-0">
<!--      Controller-->
      <div :style="{width: `${sideBarPosition}%`}">
        <SideBar></SideBar>
      </div>
<!--      View-->
      <div :style="{width: `${100 - sideBarPosition}%`}">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import SideBar from "@/components/common/SideBar";

export default {
  name: "WorkspacePage",
  components: {
    SideBar
  },
  data() {
    return {
      intro: false,
      sideBarPosition: Math.min(6, Math.round(60/window.innerWidth * 100))
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
  },
  methods: {
    startNow() {
      this.intro = false;
    },
    onResize() {
      this.sideBarPosition = Math.min(6, Math.round(60/window.innerWidth * 100))
    }
  }
}
</script>

<style scoped>

</style>
