import { createApp } from 'vue'
import App from './App.vue'
import router from "@/router";
import { createPinia } from 'pinia'

// Import Bootstrap an BootstrapVue CSS files (order is important)
import './assets/css/main.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-icons/font/bootstrap-icons.css'
import 'bootstrap';

createApp(App).use(router).use(createPinia()).mount('#app')
