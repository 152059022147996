<template>
  <div class="output" v-html="output"></div>
</template>

<script>
import { marked } from 'marked';
import hljs from "highlight.js";

let markedRenderer = new marked.Renderer()

markedRenderer.table = function(header, body) {
  const replaced_header = header.replace(/<th/g, '<th scope="col" style="text-align: center"')
  let table = `
        <table class="table table-hover table-bordered table-sm">
            <thead>${replaced_header}</thead>
            <tbody>${body}</tbody>
        </table>
    `

  return table
}



export default {
  name: "MarkdownDocument",
  props: {
    input: null
  },
  computed: {
    output() {
      if (this.input) {
        return marked(this.input, {
          renderer: markedRenderer,
          highlight: function(markdown) {
            return hljs.highlight(markdown, {language: "python", ignoreIllegals: true }).value
          },
          langPrefix: 'hljs language-',
          pedantic: false,
          gfm: true,
          breaks: false,
          sanitize: false,
          smartLists: true,
          smartypants: false,
          xhtml: false
        })
      }

      return marked('# Type here');
    }
  }
}
</script>

<style scoped>
@import "~highlight.js/styles/stackoverflow-dark.css";

.output {
  overflow: auto;
  height: inherit;
  box-sizing: border-box;
  padding: 0 20px;
  text-align: start;
}

/*table {*/
/*  border-collapse: collapse;*/
/*}*/
/*tr {*/
/*  border-bottom: solid 1px black;*/
/*}*/
/*tr:nth-child(even) {*/
/*  background-color: #f2f2f2;*/
/*}*/
</style>
