
export class Document {
    _hover = false;
    _isClicked = false;

    constructor(id, shortTitle, content, category1) {
        this._id = id;
        this._shortTitle = shortTitle;
        this._content = content;
        this._category1 = category1;
    }

    get id() {
        return this._id;
    }

    set id(value) {
        this._id = value;
    }

    get shortTitle() {
        return this._shortTitle;
    }

    set shortTitle(value) {
        this._shortTitle = value;
    }

    get content() {
        return this._content;
    }

    set content(value) {
        this._content = value;
    }

    get category1() {
        return this._category1;
    }

    set category1(value) {
        this._category1 = value;
    }

    get hover() {
        return this._hover;
    }

    set hover(value) {
        this._hover = value;
    }

    get isClicked() {
        return this._isClicked;
    }

    set isClicked(value) {
        this._isClicked = value;
    }
}
