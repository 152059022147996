<template>
<!--  <div-->
<!--      class="bg-transparent rounded-xl p-5 sm:p-6 md:p-8 lg:p-12"-->
<!--      :class="-->
<!--      pricing.recommended-->
<!--        ? 'border-4 border-blue-400	'-->
<!--        : 'border border-slate-100'-->
<!--    "-->
<!--  >-->
<!--    <div>-->
<!--      <span class="text-xs font-light">{{ pricing.type }}</span>-->
<!--    </div>-->
<!--    <h2 class="font-bold text-3xl">{{ pricing.title }}</h2>-->

<!--    <div class="font-regular text-xl mt-3 tracking-wide">-->
<!--      <span>{{ pricing.currency }}</span>-->
<!--      <span>{{ pricing.price }}</span>-->
<!--    </div>-->

<!--    <div class="text-xs opacity-50 mt-2">{{ pricing.description }}</div>-->

<!--    <div class="flex justify-center align-center">-->
<!--      <button-->
<!--          class="text-white select-none hover:shadow-xl bg-blue-500 h-10 rounded-xl transition-shadow duration-200 text-sm px-5 mt-6"-->
<!--      >-->
<!--        Buy {{ pricing.title }}-->
<!--      </button>-->
<!--    </div>-->
<!--  </div>-->

  <div class="card mb-4 box-shadow">
    <div class="card-header">
      <h4 class="my-0 font-weight-normal">{{pricing.title}}</h4>
    </div>
    <div class="card-body">
      <h1 class="card-title pricing-card-title">${{pricing.price}} <small class="text-muted">/ mo</small></h1>
      <ul class="list-unstyled mt-3 mb-4">
        <li>10 users included</li>
        <li>2 GB of storage</li>
        <li>Email support</li>
        <li>Help center access</li>
      </ul>
      <button type="button" class="btn btn-lg btn-block btn-outline-primary">Buy {{ pricing.title }}</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "PricingCard",
  props: {
    pricing: {
      type: Object,
      default: () => ({}),
      required: true
    }
  }
}
</script>

<style scoped>
@import "@/assets/css/pricing.css";
</style>