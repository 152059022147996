<template>
  <Teleport to="body">
    <SmaDialogBox :open="openDialog"
                  @close="openDialog = false; calculate($event)">
    </SmaDialogBox>
  </Teleport>
  <div class="bar d-flex flex-row justify-content-start">
    <div class="dropdown">
      <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton"
              data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        Indicator
      </button>
      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
        <a v-for="i in indicators" :key="i.code" class="dropdown-item" @click="this.selectIndicator(i.code)">{{i.code}}</a>
      </div>
    </div>
  </div>
</template>

<script>
import SmaDialogBox from "@/components/chart/dialog/SmaDialogBox";
export default {
  name: "ToolbarComponent",
  emits: ['selectIndicator'],
  components: {SmaDialogBox},
  data() {
    return {
      indicators: [{name: 'Simple Moving Average', code: "SMA"}, {name: 'Garch Volatility', code: "GARCH"}],
      openDialog: false,
      selectedConfig: {}
    }
  },
  methods: {
    async selectIndicator(indicatorName) {
      // if (indicatorName === "SMA") {
      //   this.openDialog = true;
      // }
      this.openDialog = true;
      this.selectedConfig["code"] = indicatorName;
    },
    async calculate(config) {
      // Pass additional parameter to the selected indicator
      Object.assign(this.selectedConfig, config);
      this.$emit("selectIndicator", this.selectedConfig);
    }
  }
}
</script>

<style scoped>

.bar {
  height: 40px;
  background: #343a40;
}
</style>