<template>
  <Transition name="modal">
    <div v-if="open" class="modal-mask">
      <div class="modal-wrapper">
        <div>
          <div class="modal-dialog" role="document">
            <div class="modal-content rounded-6 shadow">
              <div class="modal-header border-bottom-0">
                <h5 class="modal-title">Window</h5>
              </div>
              <div class="modal-body py-0">
                <label class="input-group mb-3">
                  <input class="form-control" type="text" v-model="config.window" />
                </label>
              </div>
              <div class="modal-footer flex-row border-top-0">
                <button type="button" class="btn btn-outline-danger mx-1" @click="close(config)">Close</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
export default {
  name: "SmaDialogBox",
  emits: ["close"],
  props: {
    open: Boolean
  },
  data() {
    return {
      config: {
        window: 5
      }
    }
  },
  methods: {
    async close(event) {
      this.$emit('close', event);
    }
  }
}
</script>

<style scoped>
.rounded-6 { border-radius: 1rem; }

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
</style>