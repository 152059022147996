import {createRouter, createWebHistory} from 'vue-router'
import AlphasCom from "@/components/AlphasCom";
import WorkspacePage from "@/views/WorkspacePage";
import AboutPage from "@/views/AboutPage";
import DocumentPage from "@/views/DocumentPage";
import LoginCom from "@/components/auth/LoginCom";
import { userProfile } from '@/store/store';
import StudioCom from "@/components/studio/StudioCom";
import HomePage from "@/views/HomePage";
import UserProfile from "@/components/profile/UserProfile";
import RegisterCom from "@/components/auth/RegisterCom";
import REPLCom from "@/components/repl/REPLCom";
import PricingPage from "@/views/PricingPage";
import NotebookCom from "@/components/notebook/NotebookCom";
import TradingViewComponent from "@/components/chart/TradingViewComponent";


const routes = [
    {path: '/', name: 'Home', component: HomePage},
    {path: '/chart', component: TradingViewComponent},
    {path: '/workspace', name: 'Workspace', component: WorkspacePage,
        meta: {requireAuth: true},
        children: [
            {
                path: '/studio',
                component: StudioCom
            },
            {
                path: '/alpha',
                component: AlphasCom
            },
            {
                path: '/notebook',
                component: NotebookCom,
                beforeEnter() {
                    window.open("http://192.168.68.105:8000/hub/", '_blank');
                }
            },
            {
                path: '/repl',
                component: REPLCom
            }
        ]
    },
    {path: '/about', name: 'About', component: AboutPage},
    {path: '/pricing', name: 'Pricing', component: PricingPage},
    // {path: '/docs', name: 'Documents', component: DocumentPage, meta: {requireAuth: true}},
    {path: '/docs', name: 'Documents', component: DocumentPage},
    {path: '/login', name: 'LogIn', component: LoginCom},
    {path: '/register', name: 'Register', component: RegisterCom},
    {path: '/profile', name: 'Profile', component: UserProfile, meta: {requireAuth: true}},
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

router.beforeEach(function (to) {
    const profile = userProfile();
    if (to.meta.requireAuth && !profile.isLogin) return '/login'
})

export default router;
