<template>
  <div class="container-fluid g-0 editor">
    <div class="d-flex g-0" :class="{'flex-row': !this.isSideBarCollapsed, 'flex-column': this.isSideBarCollapsed}" @mouseup="endDragging()">
      <div :style="{width: !this.isSideBarCollapsed ? `${dividerPosition}%` : `100%`}">
        <SectionSideBar :is-collapsed="this.isSideBarCollapsed" :posts="postGroups" @quantKnowledge="updateDocument($event)"></SectionSideBar>
      </div>
      <div class="d-flex flex-row g-0 justify-content-start" :style="{width: this.isSideBarCollapsed ? `100%` : `${100 - dividerPosition}%`}">
        <div v-if="!this.isSideBarCollapsed" class="divider"
             @mousedown="startDragging()"
        ></div>
        <div v-if="isLoading">
          Loading...
        </div>
        <div v-if="currentDocument !== null" class="container-fluid">
          <MarkdownDocument :input="currentDocument.content"></MarkdownDocument>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import restApi from "@/api/rest";
import SectionSideBar from "@/components/document/SectionSideBar";
import MarkdownDocument from "@/components/document/MarkdownDocument";
import {Document} from "@/models/documentModel";
// import {userProfile} from "@/store/store";

export default {
  name: "DocumentRoot",
  components: {MarkdownDocument, SectionSideBar},
  // setup() {
  //   const profile = userProfile();
  //   return {profile};
  // },
  data() {
    return {
      dividerPosition: 20,
      posts: [],
      postGroups: new Map(),
      currentDocument: null,
      isLoading: false,
      windowWidth: window.innerWidth,
      isSideBarCollapsed: false
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
  },
  methods: {
    async fetchData() {
      try {
        this.isLoading = true;
        const url = `${process.env.VUE_APP_BACKEND_URL}quant_knowledge/`;
        // const result = await (await restApi.get(url, this.profile.token)).json();
        const result = await (await restApi.get(url, null)).json();
        // console.log(result);

        // Push to list
        result.results.forEach(e => {
          const doc = new Document(e.id, e.short_title, null, e.category_name);
          this.posts.push(doc);

          if (this.postGroups.has(doc.category1)) {
            this.postGroups.get(doc.category1).documents.push(doc);
          } else {
            const d = {expanded: false, documents: [doc]}
            this.postGroups.set(doc.category1, d);
          }
        })

        this.isLoading = false;
      } catch (e) {
        console.log("Failed to load.");
      }

      // console.log(this.postGroups);
    },
    updateDocument(message) {
      this.currentDocument = message;
    },
    handleDragging(e) {
      const percentage = (e.pageX / window.innerWidth) * 100;

      if (percentage >= 10 && percentage <=90) {
        this.dividerPosition = percentage.toFixed(2);
      }
    },
    startDragging() {
      // document.addEventListener('mousemove', this.handleDragging)
    },
    endDragging() {
      // document.removeEventListener('mousemove', this.handleDragging)
    },
    onResize() {
      this.windowWidth = window.innerWidth;
      this.isSideBarCollapsed = this.windowWidth < 750;
    }
  },
  created() {
    this.fetchData();
  }
}
</script>

<style scoped>
.divider {
  height: 100vh;
  width: 2px;
  background: grey;
  transform: translateX(-3px);
  position: fixed;
  top: 56px;
  z-index: 9999;
  cursor: ew-resize;
}

.editor {
  top: 56px;
  z-index: 1
}
</style>
