<template>
  <div id="file-sidebar" class="d-flex flex-column flex-shrink-0 p-0 bg-dark text-white sticky-top align-items-start">
    <ul class="container-fluid nav nav-pills flex-column align-items-start"
        v-for="(aFile, index) in tickerList" :key="index">
      <li v-on:mouseover="aFile.hover = true"
          v-on:mouseleave="aFile.hover = false"
          class="align-items-center"
          :class="{'d-flex':true, 'justify-content-between': true, 'nav-item': true, 'selected': aFile.isClicked}">
        <button :class="{'nav-link': true, 'text-white': true}" @click="showData(aFile)">
          {{ aFile.name }}
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
import {userProfile} from "@/store/store";
import restApi from "@/api/rest";

export default {
  name: "TickerListSideBar",
  props: {
    tickers: null
  },
  setup() {
    const profile = userProfile();
    return {profile};
  },
  data () {
    return {
      tickerList: [],
      search: ""
    }
  },
  methods: {
    async queryTickerList() {
      const url = `${process.env.VUE_APP_TRADING_API_BACKEND}/data/ticker`;
      const resp = await (await restApi.get(url)).json();
      resp.forEach((item) => this.tickerList.push({name: item['ticker_code'], id: item['id'], isClicked: false}));
    },
    async showData(item) {
      this.tickerList.forEach((item) => item.isClicked = false);
      item.isClicked = true;
      this.$emit('open', item);
    }
  },
  mounted() {
    this.queryTickerList();
  },
}
</script>

<style scoped>
#file-sidebar {
  /*position: fixed;*/
  width: 100%;
  height: calc(100vh - 56px);
  left: 0;
  top: 56px;
  /*z-index: -1;*/
}

.selected {
  background: #b8b8b8;
  width: 100%;
  text-align: start;
  color: blue;
}

.nav-item:hover {
  background: #b8b8b8;
  width: 100%;
  text-align: start;
}
.bi {
  cursor: pointer;
}

.icon-btn {
  padding-right: 10px;
}

.icon-btn:hover {
  color: orange;
}

</style>