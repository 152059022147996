<template>

  <div class="pricing-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
    <h1 class="display-4">Pricing</h1>
    <p class="lead">Join us to maximize the potential of data</p>
  </div>

  <div class="container">
    <div class="pricing">
      <template v-for="(pricing, idx) in cards" :key="idx">
        <PricingCard :pricing="pricing" />
      </template>
    </div>
  </div>
</template>

<script>
import PricingCard from "@/components/pricing/PricingCard";

export default {
  name: "PricingCom",
  components: { PricingCard },
  data() {
    return {
      cards: [
        {
          type: "Personal",
          title: "Starter",
          price: 5,
          currency: "$",
          description: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
          recommended: false,
        },
        {
          type: "Teams",
          title: "Team",
          price: 10,
          currency: "$",
          description: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
          recommended: true,
        },
        {
          type: "Company",
          title: "Pro",
          price: 50,
          currency: "$",
          description: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
          recommended: false,
        },
      ],
    }
  }
}
</script>

<style scoped>
@import "@/assets/css/pricing.css";

.pricing {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  flex-direction: row;
  justify-content: center;
}

</style>