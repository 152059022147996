const restApi = {
    async post(endpoint, data, authToken) {
        if (authToken) {
            //  If has authToken
            return fetch(endpoint,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Token ' + authToken
                    },
                    body: JSON.stringify(data)
                });
        }

        return fetch(endpoint,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });
    },
    async get(endpoint, authToken) {
        if (authToken) {
            return fetch(endpoint,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Token ' + authToken
                    }
                });
        }

        return fetch(endpoint,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
    },
    async update(endpoint, data, authToken) {
        return fetch(endpoint,
            {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Token ' + authToken
                },
                body: JSON.stringify(data)
            });
    },
    async delete(endpoint, authToken) {
        return fetch(endpoint,
            {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Token ' + authToken
                }
            });
    }
}


export default restApi;
