<template>
  <div id="doc-sidebar" :style="{height: isCollapsed ? 'inherit': '100%'}" class="d-flex flex-column flex-shrink-0 p-0 bg-white text-black sticky-top">
    <div v-if="isCollapsed">
      <button type="button" @click="showList">
        <i class="bi bi-list-task" style="font-size: 1.25rem"></i>
      </button>
    </div>

    <div v-if="toShowList">
      <ul class="nav nav-pills flex-column align-items-start" v-for="[index, val] in postDetails" :key="index">
        <li class="nav-item">
          <div class="d-flex flex-row justify-content-between align-items-center">
            <a v-if="this.postDetails.get(index).expanded" class="nav-link text-black" @click="collapse(index)">
              {{ index }}
            </a>
            <a v-else class="nav-link text-black" @click="expand(index)">
              {{ index }}
            </a>
            <i v-if="this.postDetails.get(index).expanded" class="bi bi-chevron-down p-2" style="font-size: 1rem" @click="collapse(index)"></i>
            <i v-else class="bi bi-chevron-right p-2" style="font-size: 1rem" @click="expand(index)"></i>
          </div>
          <div v-if="this.postDetails.get(index).expanded">
            <ul v-for="(post, subIndex) in val.documents" :key="subIndex">
              <li class="sub-nav-item">
                <a
                    class="nav-link text-black"
                    :class="{'selected': val.documents[subIndex].isClicked}"
                    @click="fetchPost(val.documents, subIndex)">
                  {{ post.shortTitle }}
                </a>
              </li>
            </ul>
          </div>
        </li>

        <!--      <li v-on:mouseover="post.hover = true"-->
        <!--          v-on:mouseleave="post.hover = false"-->
        <!--          :class="{'nav-item': true, 'selected': post.hover}">-->
        <!--        <a class="nav-link text-black" @click="fetchPost(index)">-->
        <!--          {{ post.shortTitle }}-->
        <!--        </a>-->
        <!--      </li>-->
      </ul>
      <div v-if="this.isCollapsed">
        <hr/>
      </div>
    </div>
  </div>
</template>

<script>
import restApi from "@/api/rest";
import {Document} from "@/models/documentModel";
import {userProfile} from "@/store/store";

export default {
  name: "SectionSideBar",
  setup() {
    const profile = userProfile();
    return {profile};
  },
  props: {
    posts: null,
    isCollapsed: null
  },
  data () {
    return {
      postDetails: this.posts,
      toShowList: !this.isCollapsed,
      isInherit: this.isCollapsed
    }
  },
  methods: {
    async fetchPost(subList, index) {
      this.resetPostState();
      const id = subList[index].id;
      subList[index].isClicked = true;

      if (id) {
        const url = `${process.env.VUE_APP_BACKEND_URL}quant_knowledge/${id}/`;
        const result = await (await restApi.get(url, this.profile.token)).json();
        // console.log(result);

        const document = new Document(result.id, result.short_title, result.content, result.category1)
        this.$emit('quantKnowledge', document);
      } else {
        this.$emit('quantKnowledge', subList[index]);
      }
    },
    resetPostState() {
      /// Restart the click state
      Array.from(this.postDetails.values()).map(val => val.documents.map(doc => doc.isClicked = false));
    },
    expand(key) {
      this.postDetails.get(key).expanded = true;
    },
    collapse(key) {
      this.postDetails.get(key).expanded = false;
    },
    showList() {
      this.toShowList = !this.toShowList;
    }
  }
}
</script>

<style scoped>
#doc-sidebar {
  /*position: fixed;*/
  width: 100%;
  height: calc(100vh - 56px);
  left: 0;
  top: 56px;
  /*z-index: -1;*/
}

.selected {
  /*background: #b8b8b8;*/
  width: 100%;
  text-align: start;
  font-weight: bold;
}

.nav-link {
  cursor: pointer;
  padding-left: 0;
}

.nav-link:hover {
  font-weight: bold;
}

.nav-item {
  width: 100%;
}

.sub-nav-item {
  width: 100%;
  text-align: start;
}

.bi {
  text-align: center;
}

ul {
  list-style-type: none; /* Remove bullets */
  padding: 0;
  margin-left: 1em;
}

</style>
