<template>
  <div class="d-flex flex-column justify-content-center overflow-auto">
<!--    <h3>Backtest result</h3>-->
    <div class="d-flex flex-row justify-content-between">
      <ul class="nav nav-tabs">
        <li class="nav-item">
          <a class="nav-link" :class="{'active': tab ==='summary'}" @click="onClickTab('summary')">Summary</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" :class="{'active': tab ==='orders'}" @click="onClickTab('orders')">Orders</a>
        </li>
      </ul>
      <div class="d-flex flex-row">
        <i class="bi bi-arrow-down-square" style="font-size: 1.5rem; margin-right: 5px" @click="collapseSection"></i>
      </div>
    </div>
    <div v-if="loading">
      Running...
    </div>
    <div v-else-if="!loading && tab ==='summary'" class="table-responsive">
      <table class="table table-hover table-bordered table-sm" v-if="summary !== null">
        <thead>
        <tr>
          <th scope="col">Metric</th>
          <th scope="col">Result</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(value, key) in filteredSummary" v-bind:key="key">
          <td>{{ key }}</td>
          <td v-if="key === 'report'" v-on:click="onClickLink(value)" class="btn page-link"><a>Link to report</a></td>
          <td v-else>{{ value }}</td>
        </tr>
        </tbody>
      </table>
    </div>

    <div v-else-if="!loading && tab ==='orders'" class="table-responsive">
      <table class="table table-hover table-bordered table-sm" v-if="summary !== null">
        <thead>
        <tr>
          <th scope="col">Orders</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(value, idx) in filteredOrders" v-bind:key="idx">
          <td style="text-align: start">
            <span style="white-space: pre-line; padding-left: 10px">{{ value }}</span>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>

</template>

<script>
import restApi from "@/api/rest";
import {userProfile} from "@/store/store";

export default {
  name: "ResultCom",
  props: {
    backtestResult: Object,
    loading: Boolean
  },
  setup() {
    const profile = userProfile();
    return {profile};
  },
  data() {
    return {
      summary: this.backtestResult,
      tab: 'summary'
    }
  },
  methods: {
    async onClickLink(link) {
      const url = `${process.env.VUE_APP_BACKEND_URL}storage/`;
      const bucketName = link.split('/')[0];
      const objectName = link.split('/')[1];
      const data = {'bucket_name': bucketName, 'object_name': objectName};
      const presignedUrl = await (await restApi.post(url, data, this.profile.token)).json();

      window.open(presignedUrl['url']);
    },
    onClickTab(tabName) {
      this.tab = tabName;
    },
    expandSection() {
      this.$emit('expand', true);
    },
    collapseSection() {
      this.$emit('collapse', true);
    }
  },
  computed: {
    filteredSummary() {
      return Object.keys(this.summary).filter((key) => !key.includes('orders'))
          .reduce((cur, key) => {return Object.assign(cur, {[key]: this.summary[key]})}, {});
    },
    filteredOrders() {
      let orders = this.summary['orders'];
      if (orders) {
        return orders.split('\n');
      }

      return []
    }
  },
  created() {
    this.$watch(() => this.backtestResult, (backtestResult) => {
      // console.log(backtestResult);
      this.summary = backtestResult;
    })
  },
}
</script>

<style scoped>
.table {
  height: inherit;
}

.nav-item {
  cursor: pointer;
}
</style>
