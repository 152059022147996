<template>
  <div class="about-section">
    <h1>About the platform</h1>
    <p>We are quant traders who love building software</p>
    <p>This software is built to help you find the alphas in trading any kind of asset</p>
  </div>

  <h2 style="text-align:center">Our Team</h2>
  <div class="row">
    <div class="column">
      <div class="card">
        <div class="container">
          <h2>Duong Son</h2>
          <p class="title">CEO & Founder</p>
<!--          <p>Some text that describes me lorem ipsum ipsum lorem.</p>-->
          <p>duongson@example.com</p>
          <p><button class="button">Contact</button></p>
        </div>
      </div>
    </div>
  </div>

  <h2 style="text-align:center">Join our community</h2>
  <div class="row">
  </div>
</template>

<script>
export default {
  name: "AboutPage"
}
</script>

<style scoped>
*, *:before, *:after {
  box-sizing: inherit;
}

.column {
  float: left;
  width: 33.3%;
  margin-bottom: 16px;
  padding: 0 8px;
}

.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  margin: 8px;
}

.about-section {
  padding: 50px;
  text-align: center;
  background-color: #1c375e;
  color: white;
}

.container {
  padding: 0 16px;
}

.container::after, .row::after {
  content: "";
  clear: both;
  display: table;
}

.title {
  color: grey;
}

.button {
  border: none;
  outline: 0;
  display: inline-block;
  padding: 8px;
  color: white;
  background-color: #000;
  text-align: center;
  cursor: pointer;
  width: 100%;
}

.button:hover {
  background-color: #555;
}

@media screen and (max-width: 650px) {
  .column {
    width: 100%;
    display: block;
  }
}
</style>
