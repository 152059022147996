<template>
  <Transition name="modal">
    <div v-if="show" class="modal-mask">
      <div class="modal-wrapper">
        <div>
          <div class="modal-dialog" role="document">
            <div class="modal-content rounded-6 shadow">
              <div class="modal-header border-bottom-0">
                <h5 class="modal-title">Enter your file name</h5>
                <!--                <button type="button" class="btn-close" aria-label="Close"></button>-->
              </div>
              <div class="modal-body py-0">
                <label class="input-group mb-3">
                  <input class="form-control" type="text" v-model="fileName" />
                </label>
              </div>
              <div class="modal-footer flex-row border-top-0">
                <button type="button" class="btn btn-outline-danger mx-1" @click="$emit('close')">Close</button>
                <button v-if="!isUpdated" type="button" class="btn btn-outline-primary mx-1" @click="$emit('add', fileName)">Add</button>
                <button v-if="isUpdated" type="button" class="btn btn-outline-primary mx-1" @click="$emit('update', fileName)">Update</button>
              </div>
            </div>
          </div>
        </div>

        <!--        <div class="modal-container">-->
        <!--          <div class="modal-header">-->
        <!--            <slot name="header">default header</slot>-->
        <!--          </div>-->

        <!--          <div class="modal-body">-->
        <!--            <slot name="body">default body</slot>-->
        <!--          </div>-->

        <!--          <div class="modal-footer">-->
        <!--            <slot name="footer">-->
        <!--              default footer-->
        <!--              <button-->
        <!--                  class="modal-default-button"-->
        <!--                  @click="$emit('close')"-->
        <!--              >OK</button>-->
        <!--            </slot>-->
        <!--          </div>-->
        <!--        </div>-->
      </div>
    </div>
  </Transition>
</template>

<script>
export default {
  name: "AddNewFileDialogBox",
  props: {
    show: Boolean,
    isUpdated: Boolean,
    msg: String
  },
  data() {
    return {
      fileName: this.msg != null ? this.msg : this.msg
    }
  },
  created() {
    this.$watch(() => this.msg, (msg) => {
      this.fileName = msg;
    })
  }
}
</script>

<style scoped>
.bi {
  vertical-align: -.125em;
  fill: currentColor;
}

.rounded-4 { border-radius: .5rem; }
.rounded-5 { border-radius: .75rem; }
.rounded-6 { border-radius: 1rem; }

.modal-sheet .modal-dialog {
  width: 380px;
  transition: bottom .75s ease-in-out;
}
.modal-sheet .modal-footer {
  padding-bottom: 2rem;
}

.modal-alert .modal-dialog {
  width: 380px;
}

.border-right { border-right: 1px solid #eee; }

.modal-tour .modal-dialog {
  width: 380px;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 300px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
