<template>
  <nav id="navbar" class="navbar navbar-expand-lg navbar-dark bg-dark sticky-top navbar-weight">
    <div class="container">
      <a class="navbar-brand" href=""><span>&#945;</span>lpha-factor</a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse justify-content-end" id="navbarTogglerDemo02">
        <ul class="navbar-nav" v-for="(section, index) in sections" :key="index">
          <li class="nav-item">
            <router-link :to="{path: section.ref}" v-if="!section.isLogin"
                         :class="{'nav-link': true, 'active': section.isActive}"
                         v-on:click="onClick(index)">
              {{ section.name }}
            </router-link>
            <router-link :to="{path: section.ref}" v-else-if="section.isLogin && !profile.isLogin"
                         :class="{'nav-link': true, 'active': section.isActive}"
                         v-on:click="onClick(index)">
              {{ section.name }}
            </router-link>
          </li>
        </ul>
        <div v-if="profile.isLogin" class="dropdown">
          <a href="#" class="d-flex align-items-center text-white text-decoration-none dropdown-toggle" id="dropdownUser1" data-bs-toggle="dropdown" aria-expanded="false">
<!--            <img src="https://github.com/mdo.png" alt="" width="32" height="32" class="rounded-circle me-2">-->
            <i class="bi bi-person-circle" style="font-size: 1.25rem"></i>
            <!--        <strong>mdo</strong>-->
          </a>
          <ul class="dropdown-menu dropdown-menu-dark text-small shadow" aria-labelledby="dropdownUser1">
            <li>
              <router-link :to="{path: '/profile'}" v-if="profile.isLogin" style="text-decoration: none; color: inherit;">
                <a class="dropdown-item">Profile</a>
              </router-link>
            </li>
            <li><hr class="dropdown-divider"></li>
            <li><a class="dropdown-item" v-on:click="onLogout">Sign out</a></li>
          </ul>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { userProfile } from '@/store/store';

export default {
  name: "NavBar",
  setup() {
    const profile = userProfile();
    return {profile};
  },
  data() {
    return {
      sections: [
        {
          name: 'Home',
          ref: '/',
          isActive: false,
          isLogin: false
        },
        {
          name: 'Market',
          ref: '/chart',
          isActive: false,
          isLogin: false
        },
        {
          name: 'Workspace',
          ref: '/workspace',
          isActive: false,
          isLogin: false
        },
        {
          name: 'Pricing',
          ref: '/pricing',
          isActive: false,
          isLogin: false
        },
        {
          name: 'Documents',
          ref: '/docs',
          isActive: false,
          isLogin: false
        },
        // {
        //   name: 'About',
        //   ref: '/about',
        //   isActive: false,
        //   isLogin: false
        // },
        {
          name: 'Login',
          ref: '/login',
          isActive: false,
          isLogin: true
        },
        {
          name: 'Register',
          ref: '/register',
          isActive: false,
          isLogin: true
        }
      ]
    }
  },
  methods: {
    onClick(index) {
      for (let i = 0; i < this.sections.length; i++) {
        this.sections[i].isActive = i === index;
      }
    },
    async onLogout() {
      // Remove all persistent data
      this.profile.removeUserInfo();
      await this.$router.replace('/login');
    }
  }
}
</script>

<style scoped>
.dropdown-item {
  cursor: pointer;
}

#dropdownUser1 {
  padding-left: 8px;
}

.navbar-weight {
  z-index: 999999;
}
</style>
