<template>
  <h3>Alphas</h3>
  <form id="search">
    Search <input name="query" v-model="searchQuery">
  </form>
  <GridTable
      :data="gridData"
      :columns="gridColumns"
      :filter-key="searchQuery">
  </GridTable>

<!--  <div class="table-responsive tbl">-->
<!--    <table class="table table-hover table-bordered table-sm" v-if="experimentList !== null">-->
<!--      <thead>-->
<!--      <tr>-->
<!--        <th scope="col">Experiment Name</th>-->
<!--        <th scope="col">Compound Annual return</th>-->
<!--        <th scope="col">Sharpe ratio</th>-->
<!--        <th scope="col">Drawdown</th>-->
<!--      </tr>-->
<!--      </thead>-->
<!--      <tbody>-->
<!--      <tr v-for="(value, key) in experimentList" v-bind:key="key">-->
<!--        <td>{{ value.name }}</td>-->
<!--        <td>{{ value.backTestSummary.compoundAnnualReturn }}</td>-->
<!--        <td>{{ value.backTestSummary.sharpeRatio }}</td>-->
<!--        <td>{{ value.backTestSummary.drawdown }}</td>-->
<!--      </tr>-->
<!--      </tbody>-->
<!--    </table>-->
<!--  </div>-->
</template>

<script>
import restApi from "@/api/rest";
import {CodeBlock, BackTestSummary} from "@/models/codeBlockModel";
import {userProfile} from "@/store/store";
import GridTable from "@/components/common/GridTable";

export default {
  name: "AlphasCom",
  components: {
    GridTable
  },
  setup() {
    const profile = userProfile();
    return {profile};
  },
  data () {
    return {
      experimentList: [],
      searchQuery: '',
      gridColumns: ['Experiment Name', 'Compounding Annual Return',
        'Sharpe Ratio', 'Drawdown'],
      gridData: []
    }
  },
  methods: {
    async fetchData() {
      const url = `${process.env.VUE_APP_BACKEND_URL}backtest/summary/`;
      const result = await (await restApi.get(url, this.profile.token)).json();

      // Push to list
      result.results.forEach(e => {
        if (e.result) {
          const summary = BackTestSummary.fromJson(JSON.parse(e.result));
          if (!summary) {
            return;
          }

          let j = JSON.parse(e.result);
          j['Experiment Name'] = e.name;
          this.gridData.push(j);

          const block = new CodeBlock(e.id, e.name, null, e.result);
          block.backTestSummary = summary;
          this.experimentList.push(block);
        }
      })
    },
  },
  created() {
    this.fetchData();
  }
}
</script>

<style scoped>
.tbl {
  margin: 20px;
}
</style>
