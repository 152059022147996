<template>
  <div class="container-fluid g-0 editor">
    <Teleport to="body">
      <AddNewFileDialogBox :show="showModal"
                           :msg="newFileName"
                           :is-updated="isUpdated"
                           @close="showModal = false; isUpdated = false"
                           @add="addNewFile($event)"
                           @update="updateFileName($event)"
      ></AddNewFileDialogBox>
    </Teleport>

    <div class="d-flex flex-row g-0" @mouseup="endDragging()">
      <div :style="{width: `${dividerPosition}%`}">
        <DirectorySideBar :fileNames="fileNames"
                          @codeBlock="updateCodeBlock($event)"
                          @newFile="popUpDialog($event)"
                          @editFileName="popUpUpdateDialog($event)"
        ></DirectorySideBar>
      </div>
      <div class="d-flex flex-row g-0 justify-content-start" :style="{width: `${100 - dividerPosition}%`}">
        <div class="v-divider"
             @mousedown="startDragging()"
        ></div>
        <div v-if="currentCodeBlock !== null" class="container-fluid d-flex flex-column">
          <div :style="{width: `${5}%`}">
            <ul class="nav nav-tabs">
              <li class="nav-item">
                <a class="nav-link active" aria-current="page">{{ currentCodeBlock.name }}.py</a>
              </li>
            </ul>
          </div>
          <CodeEditor :current-code-block="currentCodeBlock"></CodeEditor>
<!--          <router-view></router-view>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DirectorySideBar from "@/components/studio/DirectorySideBar";
import AddNewFileDialogBox from "@/components/studio/AddNewFileDialogBox";
import restApi from "@/api/rest";
import {userProfile} from "@/store/store";
import CodeEditor from "@/components/studio/CodeEditor";
import {CodeBlock} from "@/models/codeBlockModel";

const sampleCode = `
import numpy as np
from custom_data import VnStock

class BasicTemplateAlgorithm(BaseAlgorithm):
    def Initialize(self):
        self.SetStartDate(2021, 1, 1)
        self.SetEndDate(2021, 12, 1)
        self.SetCash(100000000)
        self.AddData(VnStock, "ACB", Resolution.Daily)
        self.AddData(VnStock, "FUEVFVND", Resolution.Daily)
        self.SetBenchmark("FUEVFVND")
        self.SetWarmUp(5, Resolution.Daily)
        self.Debug("===========>Backtest stock {}".format("ACB"))

    def OnData(self, data):
        if not self.Portfolio.Invested:
            self.SetHoldings("ACB", 1)`;

export default {
  name: "StudioCom",
  setup() {
    const profile = userProfile();
    return {profile};
  },
  data() {
    return {
      sideBarPosition: 6,
      dividerPosition: 25,
      fileNames: [],
      currentCodeBlock: null,
      showModal: false,
      newFileName: null,
      currentFile: null,
      isUpdated: false
    }
  },
  components: {
    CodeEditor,
    DirectorySideBar,
    AddNewFileDialogBox
  },
  methods: {
    async fetchData() {
      const url = `${process.env.VUE_APP_BACKEND_URL}backtest/`;
      const result = await (await restApi.get(url, this.profile.token)).json();
      // console.log(result);

      // Push to list
      result.results.forEach(e => {
        this.fileNames.push(new CodeBlock(e.id, e.name, e.code, e.result));
      })

      // console.log(this.fileNames);
    },
    updateCodeBlock(message) {
      // console.log(message);
      this.currentCodeBlock = message;
    },
    popUpDialog(message){
      // console.log('received: ' + message);
      this.newFileName = message;
      this.showModal = true;
    },
    popUpUpdateDialog(message){
      this.newFileName = message.name;
      this.isUpdated = true;
      this.currentFile = message;
      this.showModal = true;
    },
    async addNewFile(message) {
      // Create a new file
      const newFile = new CodeBlock(null, message, sampleCode, null);
      newFile.isNew = true;
      this.fileNames.push(newFile);
      const newSet = [];
      this.fileNames.forEach(v => {
        newSet.push(v);
      })

      this.fileNames = newSet;
      this.showModal = false;
    },
    async updateFileName(message) {
      // Update the current file name
      if (this.currentFile) {
        this.currentFile.name = message;
        const url = `${process.env.VUE_APP_BACKEND_URL}backtest/${this.currentFile.id}/`;
        const data = {name: this.currentFile.name, code: this.currentFile.code,
          result: this.currentFile.result};
        await (await restApi.update(url, data, this.profile.token)).json();
      }
      // Reload data
      this.fileNames = [];
      await this.fetchData();
      this.showModal = false;
    },
    handleDragging(e) {
      const percentage = (e.pageX / window.innerWidth) * 100;

      if (percentage >= 10 && percentage <=90) {
        this.dividerPosition = percentage.toFixed(2);
      }
    },
    startDragging() {
      document.addEventListener('mousemove', this.handleDragging)
    },
    endDragging() {
      document.removeEventListener('mousemove', this.handleDragging)
    }
  },
  created() {
    this.fetchData();
  }
}
</script>

<style scoped>
.v-divider {
  height: 100vh;
  width: 4px;
  background: #666666;
  transform: translateX(-3px);
  position: fixed;
  top: 56px;
  z-index: 9991;
  cursor: ew-resize;
}

.editor {
  top: 56px;
}
</style>
