<template>
  <div class="d-flex flex-row g-0">
    <div class="d-flex flex-row g-0 justify-content-start" :style="{width: `${100 - dividerPosition}%`}">
<!--      <div v-if="isEditorOpened" class="d-flex flex-column" style="width: 100%">-->
<!--        <div id="firstContainer" class="TVChartContainer" :style="{height: `${55}%`}" ref="chartContainer" />-->
<!--        <MetricCodeEditor :style="{height: `${45}%`}" :current-code-block="currentCodeBlock"></MetricCodeEditor>-->
<!--      </div>-->
      <div class="d-flex flex-column" style="width: 100%; height: 100%" ref="chartCanvas">
        <ToolbarComponent @selectIndicator="this.selectIndicator($event)"></ToolbarComponent>
        <div id="firstContainer" class="TVChartContainer" ref="chartContainer"></div>
      </div>
    </div>
    <div :style="{width: `${dividerPosition}%`}">
      <div class="v-divider"></div>
      <!--      <MetricSideBar :fileNames="fileNames" @codeBlock="updateCodeBlock($event)"></MetricSideBar>-->
      <TickerListSideBar :tickers="tickers" @open="queryHistPrice($event)"></TickerListSideBar>
    </div>
  </div>
</template>

<script>
import {createChart} from 'lightweight-charts';
// import MetricCodeEditor from "@/components/chart/MetricCodeEditor";
import restApi from "@/api/rest";
import {Price} from "@/models/histPriceModel";
import ToolbarComponent from "@/components/chart/ToolbarComponent";
import TickerListSideBar from "@/components/chart/TickerListSideBar";
import {getCurrentDateInStr} from "@/utils/datetimeUtils";
import {calculateSMA} from "@/ta/ma";
import {userProfile} from "@/store/store";

let chart;
let legend;
let currentSeries;

const chartOptions = {
  rightPriceScale: {
    width: 60
  }
};

export default {
  name: "TradingViewComponent",
  setup() {
    const profile = userProfile();
    return {profile}
  },
  data() {
    return {
      dividerPosition: 20,
      fileNames: [{name: 'Simple Moving Average', isNew: false, code: "SMA"}, {name: 'Implied Volatility', isNew: false, code: "IMPL_VOL"}],
      tickers: [{name: 'ETHUSD', isNew: false}, {name: 'BTCUSD', isNew: false}],
      currentCodeBlock: null,
      isEditorOpened: false,
      prices: Array(),
      currentTicker: null
    }
  },
  components: {TickerListSideBar, ToolbarComponent},
  methods: {
    async queryHistPrice(ticker) {
      const id = ticker['id'];
      const symbolName = ticker['name'];
      const start = '20230501';
      const end = getCurrentDateInStr();
      const url = `${process.env.VUE_APP_TRADING_API_BACKEND}/data/price/${id}?start=${start}&end=${end}`;
      const histPrice = await (await restApi.get(url)).json();

      this.prices = Array()
      histPrice.forEach((item) => {
        const price = Price.fromJson(item);
        this.prices.push(price.toRecord());
      })

      await this.creatFirstChart(symbolName, this.prices);
    },
    async creatFirstChart(symbolName, prices) {
      // Remove old chart if exist
      if (chart) {
        for (let i=0; i<legend.children.length; i++) {
          legend.children[i].remove();
        } 
        chart.remove();
      }

      this.currentTicker = symbolName;
      // Add legend
      legend = document.createElement('div');
      legend.setAttribute("style", `left: 12px; top: 12px; z-index: 1; font-size: 16px; font-family: sans-serif; line-height: 18px; font-weight: 300;`);
      this.$refs.chartContainer.appendChild(legend);
      const firstRow = document.createElement('div');
      firstRow.innerHTML = symbolName;
      firstRow.style.color = 'black';
      firstRow.style.fontWeight = 'bold';
      legend.appendChild(firstRow);

      chart = createChart(this.$refs.chartContainer, chartOptions);

      const candlestickSeries = chart.addCandlestickSeries({
        upColor: '#26a69a', downColor: '#ef5350', borderVisible: false,
        wickUpColor: '#26a69a', wickDownColor: '#ef5350',
      });

      candlestickSeries.setData(prices);
      // console.log(this.prices);

      chart.subscribeCrosshairMove(param => {
        let priceFormatted = '';
        if (param.time) {
          const data = param.seriesData.get(candlestickSeries);
          const price = data.open !== undefined ? data.open : data.close;
          priceFormatted = price.toFixed(2);
        }
        firstRow.innerHTML = `${symbolName} <strong>${priceFormatted}</strong>`;
      });

      chart.timeScale().fitContent();
      window.addEventListener('resize', this.resizeHandler);
    },
    async selectIndicator(config) {
      // remove the 2nd child first
      if (legend.children.length >= 2) {
        legend.children[1].remove();
        chart.removeSeries(currentSeries);
      }

      let series = Array();
      let indicatorName = config.code;
      let secondRow;
      let rescale = false;

      switch (indicatorName) {
        case "SMA": {
          series = calculateSMA(this.prices, config);
          break;
        }
        case 'GARCH': {
          series = await this.getIndicator(this.currentTicker, "GARCH");
          rescale = true;
          break;
        }
      }
      if (series.length > 0) {
        let secondRow = document.createElement('div');
        indicatorName = indicatorName + " " + config.window;
        secondRow.innerHTML = indicatorName;
        secondRow.style.position = 'absolute';
        secondRow.style.zIndex = '999';
        secondRow.style.color = 'blue';
        secondRow.style.textAlign = 'left';
        secondRow.style.paddingLeft = '10px';
        secondRow.style.fontWeight = "bold";
        legend.appendChild(secondRow);
      }

      // Plot the series
      const lineChart = chart.addLineSeries({
        priceScaleId: '', // set as an overlay by setting a blank priceScaleId
      });
      if (rescale) {
        lineChart.priceScale().applyOptions({
        scaleMargins: {
        top: 0.7, // highest point of the series will be 70% away from the top
        bottom: 0, // lowest point will be at the very bottom.
        }
      })
      }
      lineChart.setData(series);
      // chart.timeScale().fitContent();
      currentSeries = lineChart;

      if (secondRow) {
        chart.subscribeCrosshairMove(param => {
          let priceFormatted = '';
          if (param.time) {
            const data = param.seriesData.get(lineChart);
            if (data) {
              const price = data.value;
              priceFormatted = price.toFixed(2);
            }
          }
          secondRow.innerHTML = `${indicatorName} <strong>${priceFormatted}</strong>`;
        });
      }
    },
    async getIndicator(ticker_code, indicatorCode) {
      const start = '20230501';
      const end = getCurrentDateInStr();
      const data = {
        'ticker_code': ticker_code,
        'indicator_code': indicatorCode,
        'start': start,
        'end': end,
        'config': ''
      }

      const url = `${process.env.VUE_APP_TRADING_API_BACKEND}/data/indicator/`;
      return (await restApi.post(url, data, this.profile.token)).json();
    },
    fitContent() {
      // call a method on the component.
      chart.value.fitContent();
    },
    resizeHandler() {
      if (!chart || this.$refs.chartContainer == null) return;
      const dimensions = this.$refs.chartContainer.getBoundingClientRect();
      chart.resize(dimensions.width, dimensions.height);
      this.$refs.chartContainer.focus();
    },
    updateCodeBlock(message) {
      this.currentCodeBlock = message;
    },
    createNewChart() {
      let newChart = document.createElement('div');
      newChart.id = "secondContainer";
      newChart.className = "TVChartContainer";
      newChart.setAttribute("ref", "secondChartContainer");
      newChart.style.height = "300px";
      newChart.style.width = "100%";

      this.$refs.chartCanvas.appendChild(newChart);
      return createChart(document.getElementById("secondContainer"));
    }
  },
  mounted() {
    this.$refs.chartContainer.focus();
  },
  unmounted() {
    if (chart) {
      chart.remove();
      chart = null;
    }
  },
  watch() {
    window.addEventListener('resize', this.resizeHandler);
  }
}
</script>

<style scoped>

.TVChartContainer {
  height: 90%;
  width: 100%;
}

.v-divider {
  height: 100vh;
  width: 4px;
  background: #666666;
  transform: translateX(-3px);
  position: fixed;
  top: 56px;
  z-index: 9991;
  cursor: ew-resize;
}

.h-divider {
  height: 4px;
  width: 100vh;
  background: #666666;
  transform: translateX(-3px);
  position: fixed;
  top: 56px;
  z-index: 9991;
  cursor: ew-resize;
}
</style>