
export class CodeBlock {
    _isSaved = false;
    _isClicked = false;
    _isUpdated = false;
    _isHover = false;
    _isNew = false;
    _backTestSummary = null;

    constructor(id, name, code, result) {
        this._id = id;
        this._name = name;
        this._code = code;
        this._result = result;
    }

    get id() {
        return this._id;
    }

    set id(value) {
        this._id = value;
    }

    get name() {
        return this._name;
    }

    set name(value) {
        this._name = value;
    }

    get code() {
        return this._code;
    }

    set code(value) {
        this._code = value;
    }

    get result() {
        return this._result;
    }

    set result(value) {
        this._result = value;
    }

    get isSaved() {
        return this._isSaved;
    }

    set isSaved(value) {
        this._isSaved = value;
    }

    get isClicked() {
        return this._isClicked;
    }

    set isClicked(value) {
        this._isClicked = value;
    }

    get isHover() {
        return this._isHover;
    }

    set isHover(value) {
        this._isHover = value;
    }

    get isNew() {
        return this._isNew;
    }

    set isNew(value) {
        this._isNew = value;
    }

    get isUpdated() {
        return this._isUpdated;
    }

    set isUpdated(value) {
        this._isUpdated = value;
    }

    get backTestSummary() {
        return this._backTestSummary;
    }

    set backTestSummary(value) {
        this._backTestSummary = value;
    }
}


export class BackTestSummary {
    constructor(compoundAnnualReturn, sharpeRatio, drawdown) {
        this._compoundAnnualReturn = compoundAnnualReturn;
        this._sharpeRatio = sharpeRatio;
        this._drawdown = drawdown;
    }

    /**
     * Static initialization
     * @param j
     * @returns {BackTestSummary}
     */
    static fromJson(j) {
        if (j) {
            const compoundAnnualReturn = j['Compounding Annual Return'];
            const sharpeRatio = j['Sharpe Ratio'];
            const drawdown = j['Drawdown'];
            return new BackTestSummary(compoundAnnualReturn, sharpeRatio, drawdown);
        }

        return null;
    }

    get compoundAnnualReturn() {
        return this._compoundAnnualReturn;
    }

    set compoundAnnualReturn(value) {
        this._compoundAnnualReturn = value;
    }

    get sharpeRatio() {
        return this._sharpeRatio;
    }

    set sharpeRatio(value) {
        this._sharpeRatio = value;
    }

    get drawdown() {
        return this._drawdown;
    }

    set drawdown(value) {
        this._drawdown = value;
    }
}
