<template>
  <div class="main">
    <Teleport to="body">
      <DialogBox :show="showModal" :msg="error" @close="showModal = false"></DialogBox>
    </Teleport>

    <form class="form-signin" @submit.prevent="submitForm">
<!--      <img class="mb-4" src="../assets/brand/bootstrap-logo.svg" alt="" width="72" height="57">-->
      <h1 class="h3 mb-3 fw-normal">Please sign in</h1>

      <div class="form-floating">
        <input type="email" class="form-control" id="floatingInput" placeholder="name@example.com" v-model.trim="email">
        <label for="floatingInput">Email address</label>
      </div>
      <div class="form-floating">
        <input type="password" class="form-control" id="floatingPassword" placeholder="Password" v-model.trim="password">
        <label for="floatingPassword">Password</label>
      </div>

<!--      <div class="checkbox mb-3">-->
<!--        <label>-->
<!--          <input type="checkbox" value="remember-me"> Remember me-->
<!--        </label>-->
<!--      </div>-->
      <button class="w-100 btn btn-lg btn-primary" type="submit">Sign in</button>
      <p class="mt-5 mb-3 text-muted">&copy; 2022 Lemanee Software</p>
    </form>
  </div>
</template>

<script>
import {userProfile} from '@/store/store';
import DialogBox from "@/components/common/DialogBox";
import restApi from "@/api/rest";

export default {
  name: "LoginCom",
  components: {
    DialogBox
  },
  setup() {
    const profile = userProfile();

    return {profile}
  },
  data() {
    return {
      email: '',
      password: '',
      userDetail: {
        userName: '',
        userType: ''
      },
      isLoading: false,
      error: null,
      showModal: false
    }
  },
  methods: {
    async submitForm() {
      await this.requestLogin();
    },
    async requestLogin() {
      const url = `${process.env.VUE_APP_BACKEND_URL}rest-auth/login/`;

      try {
        const response = await restApi.post(url, {email: this.email, password: this.password});

        if (!response.ok) {
          this.error = response.statusText;
          this.showModal = true;
        } else {
          const json = (await response.json());
          const token = json.key;
          const name = json.user != null ? json.user.username : null;
          this.profile.setToken(token);
          this.profile.setProfile(
              {
                token: token,
                name: name
              }
          );

          await this.$router.replace('/');
        }
      } catch (err) {
        this.error = "Failed to authenticate";
        this.showModal = true;
        // console.log(err);
      }
    },
  }
}
</script>

<style scoped>
.main {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #f5f5f5;
  height: calc(100vh - 56px);
  width: 100%;
}

.form-signin {
  width: 100%;
  max-width: 400px;
  padding: 15px;
  margin: auto;
}

.form-signin .checkbox {
  font-weight: 400;
}

.form-signin .form-floating:focus-within {
  z-index: 2;
}

.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

</style>
