<template>
  <PricingCom></PricingCom>
</template>

<script>
import PricingCom from "@/components/pricing/PricingCom";

export default {
  name: "PricingPage",
  components: {
    PricingCom
  }
}
</script>

<style scoped>

</style>