<template>
  <section class="script">
    <h3>Notebook is a Jupyter notebook interface of the Alpha-Factor, it allows users to analyze data, build models, etc... in an interactive manner</h3>
  </section>
</template>

<script>
export default {
  name: "NotebookCom",
  data() {
    return {
      iframeSrc: 'http://192.168.68.105:8000/hub/'
    }
  },
  methods: {
    async fetchData() {
      this.iframeSrc ='http://192.168.68.105:8000/hub/'
    }
  },
  created() {
    this.fetchData();
    console.log(this.iframeSrc)
  }
}
</script>

<style scoped>
.script {
  padding-left: 0;
  text-align: start !important;
  height: 100vh;
}
</style>