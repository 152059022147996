<template>
  <div class="main">
    <Teleport to="body">
      <DialogBox :show="showModal" :msg="error" @close="showModal = false"></DialogBox>
    </Teleport>

    <form class="form-signin" @submit.prevent="submitForm">
      <h1 class="h3 mb-3 fw-normal">Register an account</h1>
<!--      <div class="container-fluid">-->
<!--        <div class="shadow-lg bg-contrast p-5 rounded">-->
<!--          <div class="text-center">-->
<!--            <h2>Download the App</h2>-->
<!--          </div>-->

<!--          <div-->
<!--              class="d-flex flex-row justify-content-center"-->
<!--          >-->
<!--            <a-->
<!--                href="https://apps.apple.com/vn/app/lemanee/id1555487440"-->
<!--                class="nav-link py-3 px-4 btn btn-rounded btn-download btn-dark text-contrast mr-0 mr-md-4 mb-4 mb-md-0"-->
<!--            >-->
<!--              <img src="../../assets/apple.svg" class="icon" alt="..." />-->
<!--              <p class="ml-2">-->
<!--                <span class="small bold">Get it on the</span>-->
<!--                <span class="d-block bold text-contrast">App Store</span>-->
<!--              </p>-->
<!--            </a>-->

<!--            <a-->
<!--                href="https://play.google.com/store/apps/details?id=com.lemanee.app.prod"-->
<!--                class="nav-link py-3 px-4 btn btn-rounded btn-download btn-light text-darker"-->
<!--            >-->
<!--              <img-->
<!--                  src="../../assets/google-play.svg"-->
<!--                  class="icon"-->
<!--                  alt="..."-->
<!--              />-->
<!--              <p class="ml-2">-->
<!--                <span class="small bold">Download on</span>-->
<!--                <span class="d-block bold text-darker">Google Play</span>-->
<!--              </p>-->
<!--            </a>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->

      <div class="form-floating">
        <input type="text" class="form-control" id="floatingInput1" placeholder="name" v-model.trim="username">
        <label for="floatingInput">User name</label>
      </div>
      <div class="form-floating">
        <input type="email" class="form-control" id="floatingInput2" placeholder="name@example.com" v-model.trim="email">
        <label for="floatingInput">Email address</label>
      </div>
      <div class="form-floating">
        <input type="password" class="form-control" id="floatingPassword1" placeholder="Password" v-model.trim="password">
        <label for="floatingPassword">Password</label>
      </div>
      <div class="form-floating">
        <input type="password" class="form-control" id="floatingPassword2" placeholder="Password" v-model.trim="password2">
        <label for="floatingPassword">Confirm the Password</label>
      </div>
      <br>
      <button class="w-100 btn btn-lg btn-primary" type="submit">Register</button>
      <p class="mt-5 mb-3 text-muted">&copy; 2022 Lemanee Software</p>
    </form>
  </div>
</template>

<script>
import restApi from "@/api/rest";
import {userProfile} from "@/store/store";
import DialogBox from "@/components/common/DialogBox";

export default {
  name: "RegisterCom",
  components: {
    DialogBox
  },
  setup() {
    const profile = userProfile();
    return {profile}
  },
  data() {
    return {
      username: '',
      email: '',
      password: '',
      password2: '',
      isLoading: false,
      error: null,
      showModal: false
    }
  },
  methods: {
    async submitForm() {
      await this.requestLogin();
    },
    async requestLogin() {
      const url = `${process.env.VUE_APP_BACKEND_URL}rest-auth/registration/`;

      try {
        const data = {
          username: this.username,
          email: this.email,
          password1: this.password,
          password2: this.password2
        };

        const response = await restApi.post(url, data);

        if (!response.ok) {
          this.error = response.statusText;
          this.showModal = true;
        } else {
          const json = (await response.json());
          const token = json.key;
          const name = json.user != null ? json.user.username : null;
          this.profile.setToken(token);
          this.profile.setProfile(
              {
                token: token,
                name: name
              }
          );

          await this.$router.replace('/');
        }
      } catch (err) {
        this.error = "Failed to register";
        this.showModal = true;
        // console.log(err);
      }
    },
  }
}
</script>

<style scoped>
.main {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #f5f5f5;
  height: calc(100vh - 56px);
  width: 100%;
}

.form-signin {
  width: 100%;
  max-width: 400px;
  padding: 15px;
  margin: auto;
}

.form-signin .checkbox {
  font-weight: 400;
}

.form-signin .form-floating:focus-within {
  z-index: 2;
}

.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
  margin-bottom: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

</style>
