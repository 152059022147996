<template>
  <HomeRoot></HomeRoot>
</template>

<script>
import HomeRoot from "@/components/home/HomeRoot";

export default {
  name: "HomePage",
  components: {
    HomeRoot
  }
}
</script>

<style scoped>

</style>
