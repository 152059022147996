<template>
  <NavBar></NavBar>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import NavBar from "@/components/common/NavBar";

export default {
  name: 'App',
  data() {
    return {
      dividerPosition: 20
    }
  },
  components: {
    NavBar
  }
}
</script>

<style>
#app {
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 0;
}

.divider {
  height: 100vh;
  width: 6px;
  background: black;
  transform: translateX(-3px);
  position: fixed;
  top: 56px;
  z-index: 1;
  cursor: ew-resize;
}

.editor {
  top: 56px;
}
</style>
